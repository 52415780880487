import React from 'react';

import Icon from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';

const comment24Svg = () => (
	<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
		<title>object/comment/24</title>
		<desc>Created with Sketch.</desc>
		{/*eslint-disable-next-line react/no-unknown-property*/}
		<g id="object/comment/24" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M3,0 L21,0 C22.6568542,-3.04359188e-16 24,1.34314575 24,3 L24,21 C24,22.6568542 22.6568542,24 21,24 L3,24 C1.34314575,24 2.02906125e-16,22.6568542 0,21 L0,3 C-2.02906125e-16,1.34314575 1.34314575,3.04359188e-16 3,0 Z M18.966539,18.5857916 L18.966539,18.5849015 L18.966539,18.5840113 C18.966539,18.5840113 17.577049,16.5750268 18.2855557,15.8139803 L18.2527052,15.8317826 C19.3429887,14.690658 20,13.2477851 20,11.6785163 C20,7.99611966 16.4112979,5 12.0004439,5 C7.58870207,5 4,7.99611966 4,11.6785163 C4,15.3609129 7.58870207,18.3570326 12.0004439,18.3570326 C13.262971,18.3570326 14.4535819,18.1042406 15.5172299,17.6671952 C16.4086344,18.5760003 17.5504134,19.0646723 18.3965374,19.1955188 L18.3992009,19.1928485 C18.4453693,19.2079804 18.4933134,19.2222222 18.5448088,19.2222222 C18.797847,19.2222222 19.0020532,19.0166062 19.0020532,18.762924 C19.0020532,18.699726 18.9896232,18.6400885 18.966539,18.5857916 Z"
				id="Combined-Shape"
				fill="currentColor"
			/>
		</g>
	</svg>
);

export const Comment24Icon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.icon.accent.green', '#36B37E');
	const label = props.label || '';
	return <Icon glyph={comment24Svg} {...props} primaryColor={primaryColor} label={label} />;
};
