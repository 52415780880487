import React from 'react';

import Icon from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import { N0 } from '@atlaskit/theme/colors';

const SmartLinkEmbed24Svg = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="24" height="24" rx="3" fill="currentColor" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.8559 5.457L11.9189 6.377C11.8229 6.47039 11.7465 6.58209 11.6944 6.7055C11.6422 6.82891 11.6153 6.96153 11.6153 7.0955C11.6153 7.22948 11.6422 7.3621 11.6944 7.48551C11.7465 7.60892 11.8229 7.72062 11.9189 7.814C12.1145 8.005 12.3771 8.11193 12.6504 8.11193C12.9238 8.11193 13.1864 8.005 13.3819 7.814L14.3659 6.848C15.3329 5.898 16.9079 5.713 17.9679 6.56C18.2514 6.78362 18.484 7.06509 18.6501 7.38567C18.8162 7.70624 18.912 8.05854 18.9312 8.41909C18.9504 8.77963 18.8926 9.14012 18.7615 9.47653C18.6304 9.81294 18.4291 10.1175 18.1709 10.37L15.2679 13.222C14.7737 13.7043 14.1105 13.9743 13.4199 13.9743C12.7294 13.9743 12.0662 13.7043 11.5719 13.222L10.4619 12.132L8.99994 13.57L10.1079 14.659C11.9299 16.447 14.9099 16.447 16.7299 14.659L19.6349 11.807C20.0957 11.3546 20.455 10.8095 20.6891 10.2077C20.9233 9.606 21.0269 8.96137 20.9932 8.31656C20.9594 7.67175 20.789 7.04147 20.4933 6.46747C20.1976 5.89346 19.7834 5.38883 19.2779 4.987C17.3849 3.47 14.5829 3.761 12.8559 5.457Z"
			fill={token('color.icon.inverse', N0)}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.1439 19.543L12.0809 18.623C12.177 18.5296 12.2534 18.4179 12.3055 18.2945C12.3577 18.1711 12.3845 18.0385 12.3845 17.9045C12.3845 17.7705 12.3577 17.6379 12.3055 17.5145C12.2534 17.3911 12.177 17.2794 12.0809 17.186C11.8854 16.9953 11.6231 16.8886 11.3499 16.8886C11.0768 16.8886 10.8145 16.9953 10.6189 17.186L9.63394 18.152C8.66694 19.102 7.09194 19.287 6.03194 18.44C5.74847 18.2164 5.51593 17.9349 5.34982 17.6143C5.18371 17.2938 5.08786 16.9415 5.06865 16.5809C5.04944 16.2204 5.10732 15.8599 5.23842 15.5235C5.36952 15.1871 5.57084 14.8825 5.82894 14.63L8.73194 11.778C9.22618 11.2957 9.88938 11.0257 10.5799 11.0257C11.2705 11.0257 11.9337 11.2957 12.4279 11.778L13.5379 12.868L14.9999 11.43L13.8919 10.341C12.0699 8.553 9.08994 8.553 7.26994 10.341L4.36494 13.193C3.90424 13.6454 3.54491 14.1905 3.31075 14.7923C3.0766 15.394 2.97297 16.0386 3.00672 16.6834C3.04047 17.3283 3.21084 17.9585 3.50654 18.5325C3.80224 19.1065 4.21652 19.6112 4.72194 20.013C6.61494 21.53 9.41694 21.239 11.1439 19.543Z"
			fill={token('color.icon.inverse', N0)}
		/>
	</svg>
);

export const SmartLinkEmbed24Icon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.icon.accent.lime', '#82B536');
	const label = props.label || '';
	return <Icon glyph={SmartLinkEmbed24Svg} {...props} primaryColor={primaryColor} label={label} />;
};
