import React from 'react';

import Icon from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';

const import24Svg = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill={token('color.icon', '#44546F')}
			d="M13 6.49101V16C13 16.2652 12.8947 16.5196 12.7071 16.7071C12.5196 16.8947 12.2652 17 12 17C11.7348 17 11.4805 16.8947 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16V6.49101L9.78403 7.69701C9.58727 7.89158 9.32173 8.0007 9.04502 8.0007C8.76832 8.0007 8.50278 7.89158 8.30602 7.69701C8.209 7.60129 8.13196 7.48724 8.07938 7.3615C8.0268 7.23575 7.99972 7.10081 7.99972 6.96451C7.99972 6.82822 8.0268 6.69328 8.07938 6.56753C8.13196 6.44178 8.209 6.32774 8.30602 6.23201L11.261 3.30301C11.4578 3.10845 11.7233 2.99933 12 2.99933C12.2767 2.99933 12.5423 3.10845 12.739 3.30301L15.694 6.23301C16.102 6.63701 16.102 7.29301 15.694 7.69701C15.4973 7.89158 15.2317 8.0007 14.955 8.0007C14.6783 8.0007 14.4128 7.89158 14.216 7.69701L13 6.49001V6.49101ZM9.00002 9.00001V11H7.00003C6.99803 11 7.00003 18.991 7.00003 18.991C7.00003 18.995 16.994 19 16.994 19C16.997 19 17 11.009 17 11.009C17 11.003 15 11 15 11V9.00001H17C18.105 9.00001 19 9.90201 19 11.009V18.991C19 20.101 18.103 21 16.994 21H7.00603C6.47373 20.9992 5.9635 20.7872 5.58739 20.4105C5.21127 20.0339 5.00002 19.5233 5.00003 18.991V11.01C4.99871 10.7465 5.04947 10.4854 5.14939 10.2416C5.24931 9.99777 5.39642 9.77611 5.58226 9.58933C5.76811 9.40255 5.98904 9.25434 6.23234 9.1532C6.47565 9.05207 6.73654 9.00001 7.00003 9.00001H9.00002Z"
		/>
	</svg>
);

export const Import24Icon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.icon', '#44546F');
	const label = props.label || '';
	return <Icon glyph={import24Svg} {...props} primaryColor={primaryColor} label={label} />;
};
