import React from 'react';

import Icon from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';

const comment16Svg = () => (
	<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
		<title>object/comment/16</title>
		<desc>Created with Sketch.</desc>
		{/*eslint-disable-next-line react/no-unknown-property*/}
		<g id="object/comment/16" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M2,0 L14,0 C15.1045695,-2.02906125e-16 16,0.8954305 16,2 L16,14 C16,15.1045695 15.1045695,16 14,16 L2,16 C0.8954305,16 1.3527075e-16,15.1045695 0,14 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z M12.3540869,12.0466753 L12.3540869,12.046119 L12.3540869,12.0455626 C12.3540869,12.0455626 11.4856556,10.7899473 11.9284723,10.3142933 L11.9079407,10.3254197 C12.589368,9.6122168 13,8.71042127 13,7.72962824 C13,5.42813035 10.7570612,3.55555556 8.00027745,3.55555556 C5.24293879,3.55555556 3,5.42813035 3,7.72962824 C3,10.0311261 5.24293879,11.9037009 8.00027745,11.9037009 C8.78935686,11.9037009 9.53348871,11.7457059 10.1982687,11.4725525 C10.7553965,12.0405558 11.4690084,12.3459757 11.9978359,12.4277548 L11.9995006,12.4260859 C12.0283558,12.4355433 12.0583208,12.4444444 12.0905055,12.4444444 C12.2486543,12.4444444 12.3762832,12.3159344 12.3762832,12.1573831 C12.3762832,12.1178843 12.3685145,12.0806108 12.3540869,12.0466753 Z"
				id="Combined-Shape"
				fill="currentColor"
			/>
		</g>
	</svg>
);

export const Comment16Icon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.icon.accent.green', '#36B37E');
	const label = props.label || '';
	return <Icon glyph={comment16Svg} {...props} primaryColor={primaryColor} label={label} />;
};
