import React from 'react';

import Icon from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';

const space16Svg = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect
			width="16"
			height="16"
			rx="2"
			fill={token('color.background.accent.blue.subtlest', '#E9F2FF')}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.66667 7.33333V4.66667C8.66667 4.48986 8.59643 4.32029 8.4714 4.19526C8.34638 4.07024 8.17681 4 8 4C7.82319 4 7.65362 4.07024 7.5286 4.19526C7.40357 4.32029 7.33333 4.48986 7.33333 4.66667V7.33333H4.66667C4.48986 7.33333 4.32029 7.40357 4.19526 7.5286C4.07024 7.65362 4 7.82319 4 8C4 8.17681 4.07024 8.34638 4.19526 8.4714C4.32029 8.59643 4.48986 8.66667 4.66667 8.66667H7.33333V11.3333C7.33333 11.5101 7.40357 11.6797 7.5286 11.8047C7.65362 11.9298 7.82319 12 8 12C8.17681 12 8.34638 11.9298 8.4714 11.8047C8.59643 11.6797 8.66667 11.5101 8.66667 11.3333V8.66667H11.3333C11.5101 8.66667 11.6797 8.59643 11.8047 8.4714C11.9298 8.34638 12 8.17681 12 8C12 7.82319 11.9298 7.65362 11.8047 7.5286C11.6797 7.40357 11.5101 7.33333 11.3333 7.33333H8.66667Z"
			fill={token('color.background.accent.blue.bolder', '#0C66E4')}
		/>
	</svg>
);

export const Space16Icon = (props: GlyphProps) => {
	const label = props.label || '';
	return <Icon glyph={space16Svg} {...props} label={label} />;
};
