import React from 'react';

import Icon from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';

const import16Svg = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill={token('color.icon', '#44546F')}
			d="M8.66685 4.32733V10.6667C8.66685 10.8435 8.59661 11.013 8.47158 11.1381C8.34656 11.2631 8.17699 11.3333 8.00018 11.3333C7.82337 11.3333 7.6538 11.2631 7.52878 11.1381C7.40375 11.013 7.33351 10.8435 7.33351 10.6667V4.32733L6.52285 5.13133C6.39168 5.26104 6.21465 5.33379 6.03018 5.33379C5.84571 5.33379 5.66868 5.26104 5.53751 5.13133C5.47283 5.06752 5.42147 4.99148 5.38642 4.90765C5.35136 4.82382 5.33331 4.73386 5.33331 4.643C5.33331 4.55213 5.35136 4.46217 5.38642 4.37834C5.42147 4.29451 5.47283 4.21848 5.53751 4.15467L7.50751 2.202C7.63868 2.07229 7.81571 1.99954 8.00018 1.99954C8.18465 1.99954 8.36168 2.07229 8.49285 2.202L10.4628 4.15533C10.7348 4.42467 10.7348 4.862 10.4628 5.13133C10.3317 5.26104 10.1546 5.33379 9.97018 5.33379C9.78571 5.33379 9.60868 5.26104 9.47751 5.13133L8.66685 4.32667V4.32733ZM6.00018 6V7.33333H4.66685C4.66551 7.33333 4.66685 12.6607 4.66685 12.6607C4.66685 12.6633 11.3295 12.6667 11.3295 12.6667C11.3315 12.6667 11.3335 7.33933 11.3335 7.33933C11.3335 7.33533 10.0002 7.33333 10.0002 7.33333V6H11.3335C12.0702 6 12.6668 6.60133 12.6668 7.33933V12.6607C12.6668 13.4007 12.0688 14 11.3295 14H4.67085C4.31598 13.9995 3.97583 13.8581 3.72509 13.607C3.47435 13.3559 3.33351 13.0155 3.33351 12.6607V7.34C3.33263 7.16434 3.36647 6.99024 3.43309 6.82771C3.4997 6.66517 3.59777 6.5174 3.72167 6.39288C3.84557 6.26836 3.99285 6.16955 4.15506 6.10213C4.31726 6.0347 4.49119 6 4.66685 6H6.00018Z"
		/>
	</svg>
);

export const Import16Icon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.icon', '#44546F');
	const label = props.label || '';
	return <Icon glyph={import16Svg} {...props} primaryColor={primaryColor} label={label} />;
};
